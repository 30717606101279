import React, { useEffect, useState } from "react"
import { graphql } from "gatsby";
import { Layout, SEO, CJumbotron, CBreadCrumb, MicroCmsLayout, CFooterRestrantSearch } from "../../../components/_index"
import RestaurantPlanLayout from "../../../components/_extra/restaurantPlan_layout"

// markup
const SubPage = ({ data }: any) => {
  const news = data.microcmsRestaurantPlanRph
  return (
    <Layout>
      <SEO title={`${news.title}`}
        description={news.description && news.description.replace(/\r?\n/g, "")}
        ogimage={news.eyecatch?.url}
      />
      <RestaurantPlanLayout data={news} />
      <CFooterRestrantSearch formId="footer" />
    </Layout>
  )
}

export default SubPage;

export const query = graphql`
  query ($id: String!) {
    microcmsRestaurantPlanRph(id:{eq:$id}) {
      description
      eventclass
      fee
      note
      purpose
      pickup
      restaurantPlanRphId
      restaurants
      title
      eyecatch {
        height
        url
        width
      }
      link {
        blank
        href
      }
      publishedAt
      contents {
        fieldId
        heading_text
        section_title
        section_subtitle
        wysiwyg
        box
        caption
        label
        table
        youtube
        htmlcode
        type
        align
        image {
          url
          width
          height
        }
        images {
          caption
          image {
            url
            width
            height
          }
          link {
            href
            blank
          }
        }
        keyvalue {
          fieldId
          keyname
          value
          value2
        }
        list{
          value
        }
        tile{
          image {
            url
            width
            height
          }
          heading_text
          wysiwyg
        }
        link {
          href
          blank
        }
        pdf {
          url
        }
        margin {
          margin
        }
        accordion {
          title
          contents {
            fieldId
            heading_text
            wysiwyg
            box
            caption
            label
            table
            youtube
            htmlcode
            type
            align
            image {
              url
              width
              height
            }
            link {
              href
              blank
            }
            pdf {
              url
            }
            margin {
              margin
            }
          }
        }
        left {
          fieldId
          heading_text
          wysiwyg
          box
          caption
          label
          table
          youtube
          htmlcode
          type
          align
          image {
            url
            width
            height
          }
          link {
            href
            blank
          }
          pdf {
            url
          }
          margin {
            margin
          }
        }
        right {
          fieldId
          heading_text
          wysiwyg
          box
          caption
          label
          table
          youtube
          htmlcode
          type
          align
          image {
            url
            width
            height
          }
          link {
            href
            blank
          }
          pdf {
            url
          }
          margin {
            margin
          }
        }
      }
    }
  }
`
